

table.legende {
	background-color:#ffffA0;
	width:100%;
}

td.legendeHeader {
	font-weight:bold;
	color:#5e5e00;
	padding-left:3px;
	padding-bottom:3px;
}

td.legendeLeft {
	font-weight:bold;
	width:30px;
	vertical-align:top;
	padding-left:3px;
}

td.legendeRight {
	width:250px;
	vertical-align:top;
	padding-left:3px;
}





